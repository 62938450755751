<template>
  <div>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon id="back_icon" icon="chevron-left" style="margin-bottom: 5px" @click="regresar"></b-icon>
          <h2 style="margin-left: 10px">Registro de actividad</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
          ">
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon></b-input-group-text>
            </template>
            <b-form-input v-model="filter" type="search" placeholder="Buscar..."></b-form-input>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <b-container class="table_container" fluid>
            <b-table-simple striped bordered hover head-variant="light" selectable select-mode="single">
              <b-thead>
                <b-tr>
                  <b-th>
                    Fecha
                  </b-th>

                  <b-th>
                    Mensaje
                  </b-th>

                  <b-th>
                    Usuario
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(log, index) of listaLogs" :key="index">
                  <b-td>{{ momentVue(log.fecha).add(7, 'hours').format('DD/MM/YYYY HH:mm:ss') }}</b-td>
                  <b-td>{{ log.log_message }}</b-td>
                  <b-td>{{ log.usuario.nombre || log.usuario.razon_social }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
              :current-page="currentPage"></b-pagination>
          </b-container>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { axiosInstance } from "../../../utils/axiosInstance";
import Notification from '../../../utils/notification'
export default {
  data() {
    return {
      listaLogs: [],
      camposMarcas: [
        {
          key: "fecha",
          label: "fecha",
        },
        {
          key: "log_message",
          label: "mensaje",
          sortable: true,
        },
        {
          key: "usuario_id",
          label: "usuario",
          sortable: true,
        },
      ],
      filter: '',
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      currentId: "",
      marcaNueva: {
        nombre: "",
        oferta: 0,
      },
      marcaModificada: {
        nombre: "",
        oferta: 0,
      },
      rows: 0,
      loading: false,
      momentVue: moment,
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    async listarLogs() {
      this.loading = true;
      try {
        const result = await axiosInstance.get("logs/obtener-logs", {
          params: { limit: this.perPage, page: this.currentPage, palabras: this.filter }
        });

        this.listaLogs = result.data.logs;
        this.rows = result.data.count;
      } catch (error) {
        Notification('error', 'Error', 'Error al consultar los logs de actividad', 3000)
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.listarLogs();
  },
  watch: {
    currentPage() {
      this.listarLogs();
    },
    filter() {
      this.currentPage = 1
      this.listarLogs();
    }
  },
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}

#sidebar-right {
  width: 450px;
}

.fill {
  color: #aaa;
}

.cabecera_roles {
  display: none;
}

.table_container {
  margin-top: 20px;
}

.table_container tr:hover {
  cursor: pointer;
}

#tabla_roles {
  margin-top: 10px;
}

.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.detalles_body {
  margin: 10px;
}

.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}

.txt_dato {
  font-weight: bold;
}

.txt_dato_resp {
  color: #424242;
}

.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
